<template>
  <div class="mod-index-img">
    <el-dialog :title="!dataForm.id ? '新增' : '修改'"
               :close-on-click-modal="false"
               :visible.sync="visible">
      <el-form :model="dataForm"
               ref="dataForm"
               :rules="dataRule"
               label-width="100px">
        <el-form-item label="图片" prop="areaImgUrl">
          <pic-upload v-model="dataForm.areaImgUrl"></pic-upload>
        </el-form-item>
        <el-form-item label="顺序"
                      prop="areaIndex">
          <el-input-number v-model="dataForm.areaIndex"
                           controls-position="right"
                           :min="0"
                           label="排序号"></el-input-number>
        </el-form-item>
        <el-form-item label="园区编号" prop="areaId">
          <el-input v-model="dataForm.areaId" maxlength="64" placeholder="请输入园区编号" ></el-input>
        </el-form-item>
        <el-form-item label="园区名称" prop="areaName">
          <el-input v-model="dataForm.areaName" maxlength="64" placeholder="请输入园区名称" ></el-input>
        </el-form-item>
        <el-form-item label="备注"
                      prop="remark">
          <el-input type="textarea"
                    v-model="dataForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="状态"
                      prop="status">
          <el-radio-group v-model="dataForm.status">
            <el-radio :label="0">待上架</el-radio>
            <el-radio :label="1">发布</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="dataFormSubmit()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import PicUpload from '@/components/pic-upload'
import ProdsSelect from '@/components/prods-select'
import { Debounce } from '@/utils/debounce'
export default {
  data () {
    return {
      dataForm: {
        areaId: '',
        status: '0',
        areaName: '',
        areaImgUrl: '',
        areaIndex: 0,
        id: 0,
        remark: ''
      },
      dataRule: {
        imgUrl: [
          {required: true, message: '图片不能为空', trigger: 'blur'}
        ]
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10 // 每页显示多少条
      },
      prodsSelectVisible: false,
      visible: false
    }
  },
  components: {
    PicUpload,
    ProdsSelect
  },
  methods: {
    // 获取分类数据
    init (id) {
      this.visible = true
      this.dataForm.id = id || 0
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/admin/areaInfo/info/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          this.dataForm = data
        })
      } else {
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          this.dataForm.picUrl = ''
        })
      }
    },
    // 表单提交
    dataFormSubmit: Debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return
        }
        let param = this.dataForm
        this.$http({
          url: this.$http.adornUrl(`/admin/areaInfo`),
          method: param.id ? 'put' : 'post',
          data: this.$http.adornData(param)
        }).then(({ data }) => {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList', this.page)
            }
          })
        })
      })
    }),
    // 删除关联数据
    deleteRelation () {
      this.dataForm.relation = null
    },
    // 打开选择商品
    addProd () {
      this.prodsSelectVisible = true
      this.$nextTick(() => {
        this.$refs.prodsSelect.init(this.card.realData.prod)
      })
    }
  }
}
</script>
<style lang="scss">
//card样式
.card-prod-bottom {
  position: relative;
  text-align: left;
  .card-prod-name {
    margin: auto;
    padding: 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 118px;
    display: inline-block;
  }
  .card-prod-name-button {
    position: absolute;
    top: 24px;
    right: 10px;
  }
}
</style>
